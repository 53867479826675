.wrapper--base .bodyWithHeader {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
  background: var(--tgui--bg_color);
}

.wrapper--ios .body {
  border-radius: 12px;
  background: var(--tgui--bg_color);
}

.wrapper--ios .body > :first-child {
  padding-top: 2px;
  border-radius: 12px 12px 0 0;
}

.wrapper--ios .body > :last-child {
  padding-bottom: 2px;
  border-radius: 0 0 12px 12px;
}
