.wrapper {
  --tgui--cell--middle--padding: 16px 0;

  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
}

.wrapper--ios {
  gap: 16px;
}

.wrapper:hover,
.wrapper--hovered {
  background: var(--tgui--tertiary_bg_color);
}

.before,
.after {
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle {
  display: flex;
  flex-direction: column;
  gap: 2px;

  flex-grow: 1;
  max-inline-size: 100%;
  min-inline-size: 0;

  padding: var(--tgui--cell--middle--padding);
}

.middle > *,
.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper:not(.wrapper--multiline) .middle > *,
.wrapper:not(.wrapper--multiline) .title {
  white-space: nowrap;
}

.subhead {
  color: var(--tgui--subtitle_text_color);
}

.head {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.hint {
  color: var(--tgui--hint_color);
}

.subtitle {
  color: var(--tgui--hint_color);
}

.description {
  color: var(--tgui--hint_color);
}

.wrapper--ios {
  --tgui--cell--middle--padding: 12px 0;
  padding: 0 16px;
}
