.wrapper {
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 8px;

  text-decoration: none;
  transition: opacity .15s ease-out;
  color: var(--tgui--hint_color);
}

.wrapper:hover {
  background: var(--tgui--tertiary_bg_color);
}

.wrapper:active {
  opacity: .5;
}
